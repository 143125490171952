import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import createMutationsSharer from "vuex-shared-mutations"
import { db } from '@/plugins/firebase'
import { getDocs, collection, where, query } from "firebase/firestore"
import videoPlayer from "./modules/video-player";

Vue.use(Vuex)

export default async function () {

const token = localStorage.getItem('token') ? localStorage.getItem('token') : null
const usuario = localStorage.getItem('usuario') ? localStorage.getItem('usuario') : null
const negocio = localStorage.getItem('negocio') ? localStorage.getItem('negocio') : { id: '00001' }
const paying = localStorage.getItem('paying') ? localStorage.getItem('paying') : { pay: false, orderId: null }
const dataPay = localStorage.getItem('dataPay') ? localStorage.getItem('dataPay') : null
const fidelizacion = localStorage.getItem('fidelizacion') ? localStorage.getItem('fidelizacion') : []

const getDefaultState = () => {
  return {
    token: token,
    usuario: usuario,
    negocio: negocio,
    fidelizacion: fidelizacion,
    paying: paying,
    dataPay: dataPay
  }
}

const store = new Vuex.Store({

  state: getDefaultState,
  mutations: {
    setNegocio (state, negocio) {
      state.negocio = negocio
    },
    // setFidelizacion (state, fidelizacion) {
    //   state.fidelizacion = fidelizacion
    // },
    setUsuario (state, usuario) {
      state.usuario = usuario
    },
    setToken (state, token) {
      state.token = token
    },
    setPaying (state, paying) {
      state.paying = paying
    },
    setDataPay (state, dataPay) {
      state.dataPay = dataPay
    },
    RESET_STATE(state) {
      state.token = null
      state.usuario = null
    },
    updateData (state, dataDetails) {
      state.usuario.nombre = dataDetails.nombre
      state.usuario.avatar = dataDetails.avatar
    },
  },
  actions: {
    async setInfoNegocio ({ commit }, { dataDetails }) {
      commit("setNegocio", dataDetails)
      localStorage.setItem('negocio', negocio)
    },
    async setInfoUser ({ commit }, { data }) {
      commit("setUsuario", data)
      // localStorage.setItem('usuario', usuario)
      localStorage.setItem('usuario', usuario )
    },
    async setInfoPaying ({ commit }, { data }) {
      commit("setPaying", data)
      localStorage.setItem('paying', paying )
    },
    async setDataPaying ({ commit }, { data }) {
      commit("setDataPay", data)
      localStorage.setItem('dataPay', dataPay )
    },
    async login ({ commit }, { authDetails }) {
      const token = authDetails.accessToken
      const usuario = authDetails
      const rolQuery = query(collection(db, 'roles'), where("rol", "==", usuario.rol))
      const roles = await getDocs(rolQuery)
      // usuario['rutas'] = []
      roles.forEach((rol) => {
        usuario.rutas = rol.data().rutas
        // usuario['rutas'] = rol.data().rutas
      })
      commit("setToken", token)
      commit("setUsuario", usuario)
      localStorage.setItem('token', token)
      localStorage.setItem('usuario', usuario)
    },
    async updateProfile ({ commit }, { dataDetails }) {
      commit("updateData", dataDetails)
      localStorage.setItem('usuario', usuario )
    },
    logout ({ commit }) {
      commit("setToken", null);
      commit("setUsuario", null);
      commit("RESET_STATE")
      localStorage.setItem('token', null)
      localStorage.setItem('usuario', null)
      localStorage.clear()
    }
  },
  getters: {
    aperturaCaja: (state) => {
      return state.usuario && state.usuario.aperturaCaja ? state.usuario.aperturaCaja : false
    },
    userId: (state) => {
      return state.usuario && state.usuario.uid ? state.usuario.uid : null
    },
    personaId: (state) => {
      return state.usuario && state.usuario.personaId ? state.usuario.personaId : null
    },
    cajaId: (state) => {
      return state.usuario && state.usuario.cajaId ? state.usuario.cajaId : null
    },
    userName: (state) => {
      return state.usuario && state.usuario.nombre ? state.usuario.nombre : null
    },
    userRol: (state) => {
      return state.usuario && state.usuario.rol ? state.usuario.rol : null
    },
    user: (state) => {
      return state.usuario ? state.usuario : null
    },
    rutasUsuario: (state) => {
      return state.usuario && state.usuario.rutas ? state.usuario.rutas : []
    },
    logeado: (state) => {
      return state.token ?  true : false
    },
    negocio: (state) => {
      return state.negocio ? state.negocio : null
    },
    fidelizacion: (state) => {
      return state.fidelizacion ? state.fidelizacion : []
    },
    token: (state) => {
      return state.token ? state.token : []
    },
    logo: (state) => {
      return state.negocio && state.negocio.ruc ? '/img/logos/' + state.negocio.ruc + '.png' : '/img/logos/taked.png'
    },
    usarMesas: (state) => {
      return state.negocio && state.negocio.usarMesas ? state.negocio.usarMesas : false
    },
    paying: (state) => {
      return state.paying ? state.paying : { pay: false, video: true }
    },
    dataPay: (state) => {
      return state.dataPay ? state.dataPay : null
    },
    
  },
  plugins: [
    createPersistedState({
      storage: localStorage
    }),
    createMutationsSharer({
      predicate: (mutation) => {
        const predicate = [
          "setNegocio",
          "setUsuario",
          "setPaying",
          "setDataPay",
          "setToken",
          "RESET_STATE",
          "updateData"
        ]
        return predicate.indexOf(mutation.type) >= 0
      }
    })
  ],
  modules: {
    videoPlayer
  }
})
return store
// if (fidelizacionData && fidelizacionData.exists()) {
//   store.commit('setFidelizacion', fidelizacionData.data())
// }
}
